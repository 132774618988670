
// lib
import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  IMAGE_RESPONSE_MESSAGES,
  VALID_IMAGE_TYPES,
  MAX_IMAGE_SIZE, AVAILABLE_IMAGE_EXT
} from './ui-text-editor/ui-text-editor-toolbar.constants'
import config from '@/config'
import { uploadFile } from '@/app/shared/utils/upload-file'

@Component
export default class UiMediaUpload extends Vue {
  @Prop({
    type: String,
    required: true
  }) readonly value!: string

  @Prop({
    type: Array,
    default: () => []
  }) readonly rules!: []

  triggerFileInput(): void {
    this.$nextTick(() => {
      const fileInput = this.$refs.fileInput as HTMLInputElement

      if (fileInput) {
        fileInput.click()
      } else {
        throw new Error('Input form is not defined')
      }
    })
  }

  setImage(): void {
    uploadFile(AVAILABLE_IMAGE_EXT, this.uploadImage)
  }

  async uploadImage(event: Event): Promise<void> {
    try {
      const file = (event.target as HTMLInputElement).files?.[0]

      if (!file) {
        throw new Error(IMAGE_RESPONSE_MESSAGES.EMPTY_FILE_ERROR)
      }

      if (!VALID_IMAGE_TYPES.includes(file.type)) {
        throw new Error(IMAGE_RESPONSE_MESSAGES.EXT_ERROR)
      }

      if (file.size > MAX_IMAGE_SIZE) {
        throw new Error(IMAGE_RESPONSE_MESSAGES.SIZE_ERROR)
      }

      const formData = new FormData()

      formData.append('image', file)

      const response = await fetch(`${config.http.baseUrlApi}/upload`, {
        method: 'POST',
        body: formData
      })

      if (!response.ok) {
        throw new Error(IMAGE_RESPONSE_MESSAGES.UPLOAD_ERROR)
      }

      const result = await response.json()

      const { url } = result.data


      if (!url) {
        throw new Error(IMAGE_RESPONSE_MESSAGES.UPLOAD_ERROR)
      }

      this.$emit('input', url)

      this.$eventBus.$emit('notify', {
        type: 'success',
        message: IMAGE_RESPONSE_MESSAGES.UPLOAD_SUCCESS
      })
    } catch (error) {
      this.$eventBus.$emit('notify', {
        type: 'error',
        message: error.message
      })
    }
  }
}
