
// lib
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'

// app
import config from '@/config'
import { generateUrlFromText, isNumeric } from '@/app/shared/utils/string'
import { Heading, PageMode, Tag, TagGroup, TArticleState } from '@/app/article/shared/types'
import { TConstructorState } from '@/app/constructor/shared/types'
import AbstractConstructor, { IConstructor } from '@/app/constructor/shared/models'
import { rules } from '@/app/shared/utils/validation'
import ConstructorFile from '@/app/constructor/shared/models/ConstructorFile'
import Article from '@/app/article/shared/models/Article'
import DataMixin from '@/app/shared/mixins/DataMixin'
import UiMenu from '@/app/shared/components/ui-menu.vue'
import UiFormGroup from '@/app/shared/components/ui-form-group.vue'
import UiMediaUpload from '@/app/shared/components/ui-media-upload.vue'
import SmartConstructor from '@/app/constructor/index.vue'
import AppSectionDrawer from '@/app/article/shared/components/app-section-drawer.vue'
import SmartSectionDrawer from '@/app/article/shared/components/smart-section-drawer.vue'
import AppSelectSection from '@/app/article/shared/components/app-select-section.vue'
import SmartSelectSection from '@/app/article/shared/components/smart-select-section.vue'
import JournalSerivce from '@/app/shared/services/JournalSerivce'

@Component({
  components: {
    SmartSelectSection,
    AppSelectSection,
    SmartSectionDrawer,
    AppSectionDrawer,
    UiMenu,
    UiFormGroup,
    UiMediaUpload,
    SmartConstructor
  }
})
export default class AppArticleEditor extends Mixins(DataMixin) {
  mode!: PageMode

  config = config

  rules = rules

  sectionDrawer = false
  sectionDrawerTitle = ''
  sectionDrawerEditing = false

  settings: number[] = []

  initialLink = ''

  timeout: any = null

  isAutoSaved = false

  rubriks: Heading[] = []

  sections: Tag[] = []

  categories: Tag[] = []

  selectedSections: Tag[] = []

  selectedCategories: Tag[] = []

  selectedRubrik: Heading | null = null

  isArticleProcessed = false

  registeredFiles: ConstructorFile[] = [
    new ConstructorFile({
      type: 'component',
      name: 'article-block-text',
      title: 'Текстовый блок',
      icon: 'mdi-file-document',
      importedPath: () => import('@/app/article/editor/shared/components/app-article-block-text.vue')
    }),

    new ConstructorFile({
      type: 'component',
      name: 'article-products',
      title: 'Товары',
      icon: 'mdi-checkbox-multiple-blank',
      importedPath: () => import('@/app/article/editor/shared/components/smart-article-products.vue')
    }),

    new ConstructorFile({
      type: 'component',
      name: 'article-product',
      title: 'Товар',
      icon: 'mdi-checkbox-blank',
      importedPath: () => import('@/app/article/editor/shared/components/app-article-product.vue')
    }),

    new ConstructorFile({
      type: 'component',
      name: 'article-proscons',
      title: 'Плюсы и минусы',
      icon: 'mdi-plus-minus',
      importedPath: () => import('@/app/article/editor/proscons/index.vue')
    }),

    new ConstructorFile({
      type: 'component',
      name: 'article-testimonials',
      title: 'Отзывы и цитаты',
      icon: 'mdi-comment',
      importedPath: () => import('@/app/article/editor/testimonial/index.vue')
    }),

    new ConstructorFile({
      type: 'component',
      name: 'article-brand-slider',
      title: 'Слайдер брендов',
      icon: 'mdi-checkbox-multiple-blank',
      importedPath: () => import('@/app/article/editor/shared/components/app-article-brand-slider.vue')
    }),
  ]

  get modifiedDate(): string {
    return this.article.dateModified ? this.article.dateModified : ''
  }

  get publishDate(): string {
    return this.article.datePublished ? this.article.datePublished: ''
  }

  get createdDate(): string {
    return this.article.dateCreated ? this.article.dateCreated: ''
  }

  get getSelectedRubrik(): Heading | null {
    return this.article.heading || null
  }

  set getSelectedRubrik(value: Heading | null) {
    this.selectedRubrik = value
  }

  get link () {
    const arrLink =  this.article.link.split('/')
    const length = arrLink.length

    return arrLink[length - 1]
  }

  get settingsView () {
    return [{
      name: 'isActive',
      label: 'Видимость',
      value: this.article.isActive,
      activeMessage: 'Открыто',
      negativeMessage: 'Закрыто'
    }, {
      name: 'isSitemap',
      label: 'SEO Robots',
      value: this.article.isSitemap,
      activeMessage: 'Открыто',
      negativeMessage: 'Закрыто'
    }]
  }

  get articleConstructor (): IConstructor {
    return (this.$store.state.article.editor as TConstructorState).entity
  }

  set articleConstructor (item: IConstructor) {
    this.$store.commit('article/editor/updateItem', item)
  }

  get article (): Article {
    return (this.$store.state.article as TArticleState).entity
  }

  async fetchHeadings(): Promise<void> {
    await this.$store.dispatch('articleStore/fetchHeadings')

    this.rubriks = this.$store.getters['articleStore/headings']
  }

  async fetchSections(): Promise<void> {
    await this.$store.dispatch('articleStore/fetchSections')

    this.sections = this.$store.getters['articleStore/sections']

    this.fetchCategories(this.selectedSections)
  }

  async fetchCategories(tags: Tag[]): Promise<any> {
    await this.$store.dispatch('articleStore/fetchCategories', tags)

    this.categories = this.$store.getters['articleStore/categories']
  }

  @Watch('selectedSections')
  onSelectedSectionsChanged(newValue: TagGroup[], oldValue: TagGroup[]) {
    if (newValue.length === 0) {
      this.categories = []
      this.selectedCategories = []

      this.autoSave(() => this.handleChangeField('tags', []))

      return
    }

    if (newValue.length < oldValue.length) {
      this.selectedCategories = []

      this.autoSave(() => this.handleChangeField('tags', [...this.article.tags.filter((el) => el.type !== 2)]))
    }

    this.fetchCategories(newValue)
  }

  initial (): void {
    this.$store.commit('article/clear')

    this.$store.commit('article/editor/clear')

    this.selectMode()

    this.fetchHeadings()

    this.fetchSections()
  }

  @Watch('article', { immediate: true })
  onArticleLoad(newArticle: Article) {
    if (!this.isArticleProcessed && newArticle?.tagGroups?.length) {
      if (newArticle.tagGroups.length === 1 && newArticle.tagGroups[0].name === 'Рубрики') {
        this.selectedSections = newArticle.tagGroups[0].tags

        this.isArticleProcessed = true

        this.$forceUpdate()
      } else {
        this.selectedSections = newArticle.tagGroups[0].tags

        this.selectedCategories = newArticle.tagGroups[1].tags

        this.isArticleProcessed = true

        this.$forceUpdate()
      }
    }
  }

  selectMode (): void {
    const id = this.$route.params.id

    if (isNumeric(id)) {
      this.mode = PageMode.Update
    } else {
      this.mode = PageMode.Create
    }
  }

  fetchArticle (id: number): void {
    this.$store.dispatch('article/fetch', id).then(() => {
      this.initialLink = this.article.link
    })
  }

  handleAddItem (data: { $uuid: string, index: number, file: ConstructorFile }): void {
    this.$store.commit('article/editor/addItem', data)
  }

  handleDeleteItem (data: { $uuid: string, index: number }): void {
    this.$store.commit('article/editor/deleteItem', data)
  }

  deleteArticle() {
    this.$store.dispatch('article/deleteArticle', this.article.id)
    this.$router.push({ name: 'ArticleList' })
  }

  handleChangeTitle (value: string): void {
    const url = generateUrlFromText(value)

    this.handleChangeField('title', value)
    this.handleChangeField('link', url)
  }

  handleChangeSettings (field: string, value: boolean): void {
    console.log('DEBUG adew:', field, value)

    if (field === 'isActive') {
      if (value) {
        this.$eventBus.$emit('notify', {
          message: 'Автосохранение отключено'
        })
      } else {
        this.$eventBus.$emit('notify', {
          message: 'Автосохранение включено'
        })
      }
    }

    this.$store.commit('article/updateField', {
      field,
      value
    })
  }

  handleChangeField (field: string, value: string | Tag[] | Heading): void {
    this.$store.commit('article/updateField', {
      field,
      value
    })
  }

  autoSave (callback?: unknown): void {
    clearTimeout(this.timeout)

    if (callback && typeof callback === 'function') {
      callback()
    }

    if (this.article.isActive) {
      return
    }

    this.timeout = setTimeout(() => {
      this.handleSubmit()
    }, 1000)
  }

  notifySaved (): void {
    if (this.article.isActive) {
      this.$eventBus.$emit('notify', {
        type: 'success',
        message: 'Сохранено'
      })
    } else {
      this.isAutoSaved = true

      setTimeout(() => {
        this.isAutoSaved = false
      }, 2000)
    }
  }

  async checkLink (): Promise<boolean> {
    if (this.mode === PageMode.Update) {
      if (this.initialLink !== this.$store.getters['article/link']) {
        return await JournalSerivce.checkLink(this.$store.getters['article/link'], this.$route.params.id)
      }

      return true
    } else {
      return await JournalSerivce.checkLink(this.$store.getters['article/link'], null)
    }
  }

  getDate (): string {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  async handleSubmit (): Promise<void> {
    const form = this.$refs.form as Vue & { validate: () => boolean }
    if (!form.validate()) {
      this.$eventBus.$emit('notify', {
        type: 'error',
        message: 'Не сохранено. Заполните все обязательные поля'
      })
      return
    }

    const linkIsUnique = await this.checkLink()

    if (!linkIsUnique) {
      this.$eventBus.$emit('notify', {
        type: 'error',
        message: `Не сохранено. Ссылка ${this.link} уже существует. Переименуйте поле URL`
      })
      return
    }

    if (this.mode === PageMode.Create) {
      const data = await this.$store.dispatch('article/create', this.article.toJsonWith({
        content: this.articleConstructor as AbstractConstructor,
        link: this.$store.getters['article/link']
      }))

      if (data.id) {
        this.notifySaved()

        this.handleChangeField('datePublished', this.getDate())

        await this.$router.push({ params: { id: data.id } })
      }
    } else {
      this.handleChangeField('dateModified', this.getDate())

      const data = await this.$store.dispatch('article/update', this.article)

      if (data.id) {
        if (!this.publishDate && this.article.isActive) {
          this.handleChangeField('datePublished', this.getDate())
        }

        this.notifySaved()
      }
    }
  }

  @Watch('$route.params.id', { immediate: true })
  handleChangePage (value: number): void {
    this.initial()

    if (this.mode === PageMode.Update) {
      this.fetchArticle(value)
    }
  }

  @Watch('sectionSearch')
  handleSectionSearch (value: string): void {
    if (value) {
      this.$store.commit('article/section/updateField', {
        field: 'title',
        value: value
      })
      this.$store.commit('article/section/updateField', {
        field: 'link',
        value: generateUrlFromText(value)
      })
    }
  }
}
